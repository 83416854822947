.live {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 64px auto 0;
  z-index: 0;
}

.live__title {
  color: #2CC3FF;
  font-family: 'Caudex';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 111.111%;
  letter-spacing: -0.72px;
  margin: 0;
}

.live__table-container {
  width: 100%;
  max-height: 440px;
  position: relative;
  overflow: auto !important;
  background: rgba(24, 28, 50, 0.80);
  ;
  border-radius: 24px;
  margin-top: 24px;
  z-index: 10;
}

.live__table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.live__table-container::-webkit-scrollbar-track {
  border: none;
  outline: none;
  background-color: transparent !important;
  border-radius: 6px;
}

.live__table-container::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  outline: none;
  background: #2CC3FF;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 6px;
}

.live__table-container::-webkit-scrollbar-corner {
  background-color: transparent !important;
  border: none;
  outline: none;
}

.live__table {
  width: 100%;
  text-align: left;
  border: none;
  border-collapse: collapse;
}

.live__table-head {
  position: sticky;
  top: 0;
}

.live__table-tr-head {
  background: rgba(24, 28, 50, 1);
}

.live__table-th-head {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px 32px;
}

.live__table-th-head_type_game {
  min-width: 175px;
}

.live__table-th-head_type_player {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-th-head_type_transaction {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-th-head_type_bet {
  min-width: 190px;
  text-align: right;
}

.live__table-th-head_type_payout {
  min-width: 190px;
  text-align: right;
}

.live__table-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
}

.live__table-tr-body {
  background: rgba(24, 28, 50, 0.80);
  transition: background-color 1s linear;
}

.live__table-tr-body:nth-child(even) {
  background: rgba(24, 28, 50, 0.80);
}

.live__table-tr-body_new {
  background: rgba(255, 255, 255, 0.02);
}

.live__table-tr-body_new:nth-child(even) {
  background: #363742;
}

.live__table-tr-body_new-current-user {
  background: #2CC3FF;
}

.live__table-tr-body_new-current-user:nth-child(even) {
  background: #A45FFF;
}




.live__table-tr-body_type_not-found {
  text-align: center;
}

.live__table-td-body {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px 32px;
}

.live__table-td-body_type_game {
  min-width: 175px;

}

.live__table-td-body_type_player {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-td-body_type_transaction {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-td-body_type_bet {
  min-width: 190px;
}

.live__table-td-body_type_payout {
  min-width: 190px;
}

.live__table-cell {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: #fff !important;
}

.live__table-cell_type_bet {
  justify-content: flex-end;
}

.live__table-cell_type_payout {
  justify-content: flex-end;
}

.live__game-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live__game-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  white-space: nowrap;
  margin: 0;
  padding-left: 8px;
}

.live__id-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 119%;
  color: #5469dd;
  text-decoration: none;
  margin: 0;
  /* cursor: pointer; */
  transition: color 0.3s ease-in;
}

/* .live__id-text:hover {
  color: #7e92ff;
} */

.live__id-text_disabled {
  color: rgba(84, 105, 221, 0.44);
  pointer-events: none;
}

.live__money-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 119%;
  text-align: right;
  white-space: nowrap;
  margin: 0;
  padding-right: 4px;
}

.live__money-text_type_green {
  color: #40cf71;
}

.live__currency-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

.live__not-found {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
  padding: 50px 0;
}

@media (max-width: 900px) {
  .live {
    padding: 0 16px;
  }

  .live__title {
    font-size: 28px;
    line-height: 157%;
  }
}

@media (max-width: 710px) {
  .live {
    margin: 40px auto 0;
  }
}

@media (max-width: 550px) {
  .live__id-text:hover {
    color: #5469dd;
  }
}