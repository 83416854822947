@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-BoldItalic.1196394e.eot);
    src: url(/static/media/Rubik-BoldItalic.1196394e.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-BoldItalic.5c692368.woff2) format('woff2'),
        url(/static/media/Rubik-BoldItalic.9363153c.woff) format('woff'),
        url(/static/media/Rubik-BoldItalic.4de8f802.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Italic.1b2ee9a5.eot);
    src: url(/static/media/Rubik-Italic.1b2ee9a5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Italic.414cdc14.woff2) format('woff2'),
        url(/static/media/Rubik-Italic.42f993ca.woff) format('woff'),
        url(/static/media/Rubik-Italic.04e5655f.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-LightItalic.6021a0ae.eot);
    src: url(/static/media/Rubik-LightItalic.6021a0ae.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-LightItalic.c8af5e76.woff2) format('woff2'),
        url(/static/media/Rubik-LightItalic.0dbf3aae.woff) format('woff'),
        url(/static/media/Rubik-LightItalic.d4373e23.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-BlackItalic.13071dbc.eot);
    src: url(/static/media/Rubik-BlackItalic.13071dbc.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-BlackItalic.c07f9d98.woff2) format('woff2'),
        url(/static/media/Rubik-BlackItalic.9d114b0a.woff) format('woff'),
        url(/static/media/Rubik-BlackItalic.7c9a57b2.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-MediumItalic.ed35c495.eot);
    src: url(/static/media/Rubik-MediumItalic.ed35c495.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-MediumItalic.166f5a8d.woff2) format('woff2'),
        url(/static/media/Rubik-MediumItalic.0fcdfe1c.woff) format('woff'),
        url(/static/media/Rubik-MediumItalic.4785a4bd.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Light.e750fbcd.eot);
    src: url(/static/media/Rubik-Light.e750fbcd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Light.19357e65.woff2) format('woff2'),
        url(/static/media/Rubik-Light.dd918667.woff) format('woff'),
        url(/static/media/Rubik-Light.3d8a8581.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-ExtraBold.51d3859a.eot);
    src: url(/static/media/Rubik-ExtraBold.51d3859a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-ExtraBold.c5c6e5da.woff2) format('woff2'),
        url(/static/media/Rubik-ExtraBold.ff3d317c.woff) format('woff'),
        url(/static/media/Rubik-ExtraBold.4101863e.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-ExtraBoldItalic.babe1ff1.eot);
    src: url(/static/media/Rubik-ExtraBoldItalic.babe1ff1.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-ExtraBoldItalic.1676686e.woff2) format('woff2'),
        url(/static/media/Rubik-ExtraBoldItalic.58854e63.woff) format('woff'),
        url(/static/media/Rubik-ExtraBoldItalic.056f5cda.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Black.e9c642c2.eot);
    src: url(/static/media/Rubik-Black.e9c642c2.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Black.314a52e5.woff2) format('woff2'),
        url(/static/media/Rubik-Black.d9706d41.woff) format('woff'),
        url(/static/media/Rubik-Black.a6ec28fd.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-LightItalic_1.64e6a43f.eot);
    src: url(/static/media/Rubik-LightItalic_1.64e6a43f.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-LightItalic_1.da03b1c7.woff2) format('woff2'),
        url(/static/media/Rubik-LightItalic_1.3feb3e89.woff) format('woff'),
        url(/static/media/Rubik-LightItalic_1.20894e3a.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Medium.f0420aa0.eot);
    src: url(/static/media/Rubik-Medium.f0420aa0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Medium.5f4249fe.woff2) format('woff2'),
        url(/static/media/Rubik-Medium.74fded82.woff) format('woff'),
        url(/static/media/Rubik-Medium.8e2eadb2.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Bold.28198b73.eot);
    src: url(/static/media/Rubik-Bold.28198b73.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Bold.79cd5ccd.woff2) format('woff2'),
        url(/static/media/Rubik-Bold.704be18a.woff) format('woff'),
        url(/static/media/Rubik-Bold.d437e92b.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-SemiBoldItalic.0515ad5c.eot);
    src: url(/static/media/Rubik-SemiBoldItalic.0515ad5c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-SemiBoldItalic.ec685a6f.woff2) format('woff2'),
        url(/static/media/Rubik-SemiBoldItalic.5aaeaf84.woff) format('woff'),
        url(/static/media/Rubik-SemiBoldItalic.bff4a9a1.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Light_1.be89c86a.eot);
    src: url(/static/media/Rubik-Light_1.be89c86a.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Light_1.cbd5814a.woff2) format('woff2'),
        url(/static/media/Rubik-Light_1.db0dd409.woff) format('woff'),
        url(/static/media/Rubik-Light_1.08a19e0c.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-Regular.b379b3bd.eot);
    src: url(/static/media/Rubik-Regular.b379b3bd.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-Regular.855df8a4.woff2) format('woff2'),
        url(/static/media/Rubik-Regular.882e9e0e.woff) format('woff'),
        url(/static/media/Rubik-Regular.29463d3b.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url(/static/media/Rubik-SemiBold.58eaa740.eot);
    src: url(/static/media/Rubik-SemiBold.58eaa740.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Rubik-SemiBold.9c329416.woff2) format('woff2'),
        url(/static/media/Rubik-SemiBold.e9c53c58.woff) format('woff'),
        url(/static/media/Rubik-SemiBold.d4d1d764.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Caudex';
    src: url(/static/media/Caudex-Italic.c2619b98.eot);
    src: url(/static/media/Caudex-Italic.c2619b98.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Caudex-Italic.06a145b4.woff2) format('woff2'),
        url(/static/media/Caudex-Italic.d0452be6.woff) format('woff'),
        url(/static/media/Caudex-Italic.47ab01ad.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Caudex';
    src: url(/static/media/Caudex.c6763c4d.eot);
    src: url(/static/media/Caudex.c6763c4d.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Caudex.0e4f1fd6.woff2) format('woff2'),
        url(/static/media/Caudex.864b1ae2.woff) format('woff'),
        url(/static/media/Caudex.6013887a.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caudex';
    src: url(/static/media/Caudex-Bold.f32556db.eot);
    src: url(/static/media/Caudex-Bold.f32556db.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Caudex-Bold.fa055147.woff2) format('woff2'),
        url(/static/media/Caudex-Bold.6172b467.woff) format('woff'),
        url(/static/media/Caudex-Bold.67e81f51.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caudex';
    src: url(/static/media/Caudex-BoldItalic.498d5ffb.eot);
    src: url(/static/media/Caudex-BoldItalic.498d5ffb.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Caudex-BoldItalic.584f30d9.woff2) format('woff2'),
        url(/static/media/Caudex-BoldItalic.0165e648.woff) format('woff'),
        url(/static/media/Caudex-BoldItalic.f40aa796.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


body {
  margin: 0;
  font-family: 'Rubik', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #14182D;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  width: 100%;
  height: 64px;
  position: relative;
  /* background: rgba(24, 28, 50, 0.90); */
  /* border-bottom: 1px solid #393a40; */
  z-index: 1000;

}

.header__container {
  width: 100%;
  max-width: 1150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px 32px;
  margin: 0 auto;
}

.header__sound-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  gap: 8px;
}

.header__sound-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1E223C;
  border-radius: 8px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.sound-icon {
  width: 16px;
  height: 11px;
}

.header__burger-button {
  display: none;
}

.header__info-block {
  display: grid;
  grid-template-columns: repeat(2, minmax(-webkit-min-content, -webkit-max-content));
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.header__info-block_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.header__demo-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  gap: 8px;
}

.header__demo-block_user {
  grid-template-columns: 1fr;
}

.header__demo-box {
  display: flex;
  align-items: center;
  background: #1E223C;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 7px;
  text-decoration: none;
  color: #ffffff;
}

.header__currency-icon {
  width: 18px;
  height: 18px;
}

.header__demo-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  margin: 0;
  padding-left: 6px;
}

.header__login-btn {
  display: flex;
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: #ffffff;
  background: #8F00FF;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  padding: 7px 12px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in;
}

.header__login-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 12px;
  box-shadow: 0px 0px 0px 2px rgba(174, 84, 250, 0.24);
  transition: opacity 0.3s ease-in;
}

.header__login-btn:hover::after {
  opacity: 1;
}

.header__box-with-drop {
  position: relative;
}

.header__user-box {
  width: 132px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 12px;
  background: linear-gradient(#171821, #171821),
    linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-sizing: border-box;
  padding: 7px 12px;
  cursor: pointer;
}

.header__user-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  background: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  padding-right: 2px;
}

.header__arrow-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}

.header__arrow-icon_reverse {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header__logout-popup_opened .header__logout-overlay {
  width: 100vw;
}

.header__logout-overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.header__logout-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1E223C;
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  padding: 7px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 40px;
  z-index: 100;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.header__logout-popup_opened .header__logout-btn {
  visibility: visible;
  opacity: 1;
}

.header__logout-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: #ffffff;
  margin: 0;
  padding-right: 4px;
}

.header__logout-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__drop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  background: #202228;
  border-radius: 12px;
  grid-gap: 6px;
  gap: 6px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  font-family: 'Rubik';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;

}

.header__drop-box-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  grid-gap: 6px;
  gap: 6px;
  cursor: pointer;
  cursor: pointer;
  white-space: nowrap;
}

.header__drop-inside{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 6px;
  gap: 6px;
}

.header__drop-soon{
  font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 1px 6px;
box-sizing: border-box;

background: #E874EB;
border-radius: 16px;
margin: 0 0 0 auto;


}

.header__drop-box-item_inactive{
  opacity: 0.3;
  pointer-events: none;
}

.header__drop-arrow {
  width: 16px;
  height: 16px;
  transition: all 0.2s linear;
}

.header__drop-container {
  position: relative;
}

.header__drop-arrow_open{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.header__drop-box {
  min-width: 100%;
  position: absolute;
  top: calc(100% + 16px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  grid-gap: 16px;
  gap: 16px;
  background: #202228;
  /* Shadow */

  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 16px;

  box-sizing: border-box;
}

@media (max-width: 1150px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
  }

  .header__sound-block {
    display: none;
  }

  .header__burger-button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
  }

  .header__burger-button:hover {
    background: rgba(255, 255, 255, 0.04);
  }
}

@media (max-width: 550px) {
  .header__container {
    padding: 16px;
  }

  .header__burger-button:hover {
    background: transparent;
  }

  .header__info-block {
    grid-template-columns: 1fr;
  }

  .header__demo-block {
    display: none;
  }

  .header__login-btn:hover {
    background-image: linear-gradient(92.29deg,
        #9747ff -42.66%,
        #d76bf0 118.44%);
  }
}
.side-bar {
  width: 100%;
  max-width: 320px;
  background-color: #181C32;
  z-index: 10000;
  border-radius: 0 0 20px 20px;

}

.side-bar__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-sizing: border-box;
  padding: 16px 32px;
}

.side-bar__main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.side-bar__logo-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-bar__logo {
  width: 90px;
  height: 32px;
  cursor: pointer;
}

.side-bar__close-button {
  display: none;
}

.side-bar__sound-block {
  display: none;
}

.side-bar__links-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-bar__links-list_type_games {
  margin: 40px 0 0;
}

.side-bar__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  margin-top: 16px;
}

.side-bar__link_selected {
  opacity: 1;
}

.side-bar__link_disabled {
  opacity: 0.15;
  pointer-events: none;
}

.side-bar__link:hover {
  opacity: 0.8;
}

.side-bar__link-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-bar__link-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #ffffff;
  margin: 0;
  padding-left: 8px;
}

.side-bar__nav-block {
  display: flex;
  flex-direction: column;
  padding-top: 108px;
}

.side-bar__nav-block_type_network {
  padding-top: 40px;
}

.side-bar__nav-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;

  color: #FFF;
  opacity: 0.5;
  margin: 0;
}

.side-bar__info-block {
  display: none;
}

.side-bar__powered-by{

  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  margin: auto 0 0;
}

@media (max-width: 1150px) {
  .side-bar {
    position: fixed;
    top: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }

  .side-bar_opened {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .side-bar__container {
    /* height: 100vh; */
    position: relative;
    bottom: 0;
    top: unset;
    padding: 0;
  }

  .side-bar__main-content {
    flex: auto;
    height: calc(100% - 57px);
    box-sizing: border-box;
    padding: 16px;
  }

  .side-bar__close-button {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
  }

  .side-bar__close-button:hover {
    background: rgba(255, 255, 255, 0.04);
  }

  .side-bar__close-button .close-icon-fill {
    fill-opacity: 0.5;
  }

  .side-bar__sound-block {
    display: grid;
    grid-template-columns: repeat(2, 32px);
    grid-gap: 8px;
    gap: 8px;
    margin-top: 24px;
  }

  .side-bar__sound-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1E223C;
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .side-bar__links-container {
    width: 100%;
    height: calc(100% - 38px - 32px - 24px);
    min-width: 100%;
    overflow: scroll;
  }

  .side-bar__links-list_type_games {
    margin: 0;
  }

  .side-bar__nav-block {
    padding-top: 44px;
  }

  .side-bar__nav-block_type_network {
    padding-top: 44px;
  }

  .side-bar__info-block {
    flex-shrink: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    gap: 8px;
    border-top: 1px solid #393a40;
    box-sizing: border-box;
    padding: 12px 16px;
  }

  .side-bar__demo-block {
    display: flex;
    align-items: center;
    background: #1E223C;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 7px 16px;
  }

  .side-bar__currency-icon {
    width: 18px;
    height: 18px;
  }

  .side-bar__demo-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 129%;
    margin: 0;
    padding-left: 6px;
    white-space: nowrap;
  }

.side-bar__info-block_user{
  grid-template-columns: 1fr;
}

}

@media (max-width: 550px) {
  .side-bar__main-content {
    padding: 12px 16px;
  }

  .side-bar__close-button:hover {
    background: transparent;
  }

  .side-bar__sound-block {
    margin-top: 20px;
  }

  .side-bar__link {
    margin-top: 14px;
  }

  .side-bar__link:hover {
    opacity: 0.5;
  }

  .side-bar__link_selected:hover {
    opacity: 1;
  }

  .side-bar__nav-block {
    padding-top: 36px;
  }

  .side-bar__nav-block_type_network {
    padding-top: 36px;
  }
}

.banner {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 40px 32px 48px;
  margin: 0 auto;
}

.banner__title-box {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
}

.banner__title {
  color: #FFF;
  font-family: 'Caudex';
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 49.2px */
  letter-spacing: -0.82px;
  margin: 0;
  
}

.banner__title_type_gradient {

  font-family: 'Caudex';
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 49.2px */
  letter-spacing: -0.82px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #BD00FF 0%, #0AF0FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner__subtitle {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #FFF;
  margin: 0;
  padding-top: 16px;
}

.banner__subtitle_type_return {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  color: #0AF0FF;


  box-sizing: border-box;


}

.banner__image {
  width: 808px;
  height: 538px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: -74px;
  right: -175px;
  z-index: -1;
}

.banner__back-ellipse {
  width: 1005px;
  height: 1005px;

  position: absolute;
  top: -360px;
  right: -580px;
  z-index: -2;
}

@media (max-width: 1150px) {
  .banner {
    padding: 88px 32px 96px;
  }

  .banner__subtitle {
    padding-top: 20px;
  }

  .banner__image {
    top: -26px;
    right: -80px;
  }

  .banner__back-ellipse {
    top: -312px;
    right: -546px;
  }
}

@media (max-width: 1040px) {
  .banner__image {
    right: -175px;
  }
}

@media (max-width: 900px) {
  .banner {
    padding: 68px 16px 0;
  }

  .banner__title {
    font-size: 36px;
  }

  .banner__image {
    width: 688px;
    height: 482px;
    top: -46px;
    right: -150px;
  }
}

@media (max-width: 710px) {
  .banner__title {
    font-size: 32px;
  }

  .banner__image {
    width: 568px;
    height: 427px;
    right: -120px;
  }
}

@media (max-width: 620px) {
  .banner {
    padding: 268px 16px 0;
  }

  .banner__title {

  }

  .banner__subtitle {
    font-size: 14px;
    line-height: 200%;
    padding-top: 20px;
  }

  .banner__image {
    top: -101px;
    right: -50px;
  }
}

@media (max-width: 460px) {
  .banner__image {
    right: -120px;
  }
}
.games {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 64px auto 0;
}

.games__title {
  color: #BD00FF;
  font-family: 'Caudex';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 111.111% */
  letter-spacing: -0.72px;
  margin: 0;
}

.games__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 250px;
  grid-gap: 24px;
  gap: 24px;
  list-style: none;
  padding: 0;
  margin: 24px 0 0;
}

.games__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
  bottom: 0;
  cursor: pointer;
  transition: bottom 0.3s ease-in;
}

.games__link:hover {
  bottom: 5px;
}

.games__link_disabled {
  pointer-events: none;
}

.games__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 20px 20px 30px 32px;
}

.games__return {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  /* text-transform: uppercase; */
  color: #FFF;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  padding: 4px 8px;
  margin: 0;
  position: relative;
  z-index: 1;
}

.games__text-block {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.games__card-title {
  color: #FFF;
  font-family: 'Caudex';
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 88.462%;
  letter-spacing: -1.04px;
  margin: 0;
  max-width: 200px;
}

.games__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #FFF;
  margin: 0;
  padding-top: 8px;
}

.games__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.games__back-ellipse {
  width: 609px;
  height: 609px;
  position: absolute;
  left: -31px;
  top: -185px;
  z-index: -2;
}

.games__card-bg-fade {
  z-index: 0;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #151A2F 0%, rgba(21, 26, 47, 0.00) 100%);
}

.games__back-ellipse_type_coin-flip {
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #f3c2d7 0%,
      #eb74a6 100%);
}

.games__back-ellipse_type_mega-dice {
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #8d99d8 0%,
      #3f56ce 100%);
}

.games__back-ellipse_type_crash {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #F4AFFF 0%, #CF48FF 100%);
}


.games__back-ellipse_type_limbo {
  background: radial-gradient(71.24% 74.44% at 78.49% 51.97%, #FFCF33 0%, #FF6E00 100%);
}

.games__back-ellipse_type_lucky-sevens {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #95DFFF 0%, #1E93FF 100%);
}

/* .games__back-ellipse_type_dragon {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #AFFFDE 0%, #08D27D 100%);
} */

.games__back-ellipse_type_dragon {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #DEDEDE 0%, #757278 100%);
}

.games__back-ellipse_type_shuttle {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #C395FF 0%, #811EFF 100%);
}


.games__back-ellipse_type_roulette {
  /*background: radial-gradient(
    45.48% 43.53% at 78.49% 51.97%,
    #f1c4ff 0%,
    #b67af3 100%
  );*/
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #dedede 0%,
      #757278 100%);
}

.games__back-ellipse_type_plinko {
  /*background: radial-gradient(
      45.48% 43.53% at 78.49% 51.97%,
      #ceeff7 0%,
      #1abcde 100%
    ),
    radial-gradient(45.48% 43.53% at 78.49% 51.97%, #8d99d8 0%, #3f56ce 100%);*/
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #dedede 0%,
      #757278 100%);
}

@media (max-width: 1150px) {
  .games__list {
    grid-gap: 16px;
    gap: 16px;
  }
}

@media (max-width: 900px) {
  .games {
    padding: 0 16px;
  }

  .games__title {
    font-size: 28px;

  }

  .games__list {
    grid-auto-rows: 200px;
    margin: 20px 0 0;
  }

  .games__card {
    padding: 28px 12px;
  }

  .games__card-title {
    font-size: 24px;

  }

  .games__card-text {
    font-size: 14px;

  }
}

@media (max-width: 710px) {
  .games {
    margin: 40px auto 0;
  }
}

@media (max-width: 700px) {

}

@media (max-width: 585px) {
  .games__card-title {
    font-size: 20px;
  }

  .games__card-text {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .games__link:hover {
    bottom: 0;
  }
}

@media (max-width: 525px) {
  .games__list {
    grid-template-columns: 1fr;
  }

  .games__card-title {
    font-size: 24px;
  }

  .games__card-text {
    font-size: 14px;
  }

  .games__image {
    object-fit: contain;
    right: 0;
  }
}
.live {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 64px auto 0;
  z-index: 0;
}

.live__title {
  color: #2CC3FF;
  font-family: 'Caudex';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 111.111%;
  letter-spacing: -0.72px;
  margin: 0;
}

.live__table-container {
  width: 100%;
  max-height: 440px;
  position: relative;
  overflow: auto !important;
  background: rgba(24, 28, 50, 0.80);
  ;
  border-radius: 24px;
  margin-top: 24px;
  z-index: 10;
}

.live__table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.live__table-container::-webkit-scrollbar-track {
  border: none;
  outline: none;
  background-color: transparent !important;
  border-radius: 6px;
}

.live__table-container::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  outline: none;
  background: #2CC3FF;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 6px;
}

.live__table-container::-webkit-scrollbar-corner {
  background-color: transparent !important;
  border: none;
  outline: none;
}

.live__table {
  width: 100%;
  text-align: left;
  border: none;
  border-collapse: collapse;
}

.live__table-head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.live__table-tr-head {
  background: rgba(24, 28, 50, 1);
}

.live__table-th-head {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px 32px;
}

.live__table-th-head_type_game {
  min-width: 175px;
}

.live__table-th-head_type_player {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-th-head_type_transaction {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-th-head_type_bet {
  min-width: 190px;
  text-align: right;
}

.live__table-th-head_type_payout {
  min-width: 190px;
  text-align: right;
}

.live__table-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
}

.live__table-tr-body {
  background: rgba(24, 28, 50, 0.80);
  transition: background-color 1s linear;
}

.live__table-tr-body:nth-child(even) {
  background: rgba(24, 28, 50, 0.80);
}

.live__table-tr-body_new {
  background: rgba(255, 255, 255, 0.02);
}

.live__table-tr-body_new:nth-child(even) {
  background: #363742;
}

.live__table-tr-body_new-current-user {
  background: #2CC3FF;
}

.live__table-tr-body_new-current-user:nth-child(even) {
  background: #A45FFF;
}




.live__table-tr-body_type_not-found {
  text-align: center;
}

.live__table-td-body {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px 32px;
}

.live__table-td-body_type_game {
  min-width: 175px;

}

.live__table-td-body_type_player {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-td-body_type_transaction {
  min-width: 144px;
  padding: 16px 24px;
}

.live__table-td-body_type_bet {
  min-width: 190px;
}

.live__table-td-body_type_payout {
  min-width: 190px;
}

.live__table-cell {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: #fff !important;
}

.live__table-cell_type_bet {
  justify-content: flex-end;
}

.live__table-cell_type_payout {
  justify-content: flex-end;
}

.live__game-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live__game-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  white-space: nowrap;
  margin: 0;
  padding-left: 8px;
}

.live__id-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 119%;
  color: #5469dd;
  text-decoration: none;
  margin: 0;
  /* cursor: pointer; */
  transition: color 0.3s ease-in;
}

/* .live__id-text:hover {
  color: #7e92ff;
} */

.live__id-text_disabled {
  color: rgba(84, 105, 221, 0.44);
  pointer-events: none;
}

.live__money-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 119%;
  text-align: right;
  white-space: nowrap;
  margin: 0;
  padding-right: 4px;
}

.live__money-text_type_green {
  color: #40cf71;
}

.live__currency-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

.live__not-found {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin: 0;
  padding: 50px 0;
}

@media (max-width: 900px) {
  .live {
    padding: 0 16px;
  }

  .live__title {
    font-size: 28px;
    line-height: 157%;
  }
}

@media (max-width: 710px) {
  .live {
    margin: 40px auto 0;
  }
}

@media (max-width: 550px) {
  .live__id-text:hover {
    color: #5469dd;
  }
}
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 64px;
}

@media (max-width: 710px) {
  .main {
    padding: 0 0 40px;
  }
}

.tables {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
    z-index: 0;
}

.tables__title-block {
  display: grid;
  grid-template-columns: repeat(2, minmax(-webkit-min-content, -webkit-max-content));
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  grid-gap: 32px;
  gap: 32px;
}

.tables__title-container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.tables__title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 133%;
  color: #ffffff;
  opacity: 0.5;
  margin: 0;
  transition: opacity 0.3s ease-in;
}

.tables__title-container_selected .tables__title {
  opacity: 1;
}

.tables__title-border {
  width: 28px;
  height: 4px;
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.3s ease-in;
}

.tables__title-container_selected .tables__title-border {
  background-color: #8F00FF;
}

.tables__table-container {
  width: 100%;
  max-height: 440px;
  position: relative;
  overflow: auto !important;
  background: rgba(24, 28, 50, 0.80);
  border-radius: 24px;
  margin-top: 24px;
  z-index: 10;
}

.tables__table-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.tables__table-container::-webkit-scrollbar-track {
  border: none;
  outline: none;
  background-color: transparent !important;
  border-radius: 6px;
}

.tables__table-container::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  outline: none;
  background: #2CC3FF;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 6px;
}

.tables__table-container::-webkit-scrollbar-corner {
  background-color: transparent !important;
  border: none;
  outline: none;
}

.tables__table {
  width: 100%;
  text-align: left;
  border: none;
  border-collapse: collapse;
}

.tables__table-head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.tables__table-tr-head {
  background: rgba(24, 28, 50, 1);
}

.tables__table-th-head {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px;
}

.tables__table-th-head_type_bet-size {
  text-align: right;
}

.tables__table-th-head_type_payout {
  text-align: right;
}

.tables__table-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  margin: 0;
}

.tables__table-tr-body {
  background: rgba(255, 255, 255, 0.02);

  transition: background-color 1s linear;
}

.tables__table-tr-body:nth-child(even) {
  background: rgba(24, 28, 50, 0.80);
}


.tables__table-tr-body_new {
  background: rgba(24, 28, 50, 0.80);
}

.tables__table-tr-body_new:nth-child(even) {
  background: rgba(24, 28, 50, 0.80);
}

.tables__table-tr-body_new-current-user {
  background: #503C61;
}

.tables__table-tr-body_new-current-user:nth-child(even) {
  background: #503C61;
}

.tables__table-tr-body_type_not-found {
  text-align: center;
}

.tables__table-td-body {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  box-sizing: border-box;
  padding: 16px;
}

.tables__table-td-body_type_time {
  width: 105px;
}

.tables__table-td-body_type_player {
  width: 115px;
}

.tables__table-td-body_type_transaction {
  width: 115px;
}

.tables__table-td-body_type_bet {
  width: 105px;
}

.tables__table-td-body_type_result {
  width: 105px;
}

.tables__table-cell {
  display: flex;
  align-items: center;
}

.tables__table-cell_type_bet-size {
  justify-content: flex-end;
}

.tables__table-cell_type_payout {
  justify-content: flex-end;
}

.tables__table-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  white-space: nowrap;
  margin: 0;
}

.tables__table-text_type_range {
  padding-right: 4px;
}

.tables__id-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129%;
  color: #5469dd;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  /* cursor: pointer; */
  transition: color 0.3s ease-in;
}

/* .tables__id-text:hover {
  color: #7e92ff;
} */

.tables__id-text_disabled {
  color: rgba(84, 105, 221, 0.44);
  pointer-events: none;
}

.tables__coin-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
}

.tables__money-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129%;
  text-align: right;
  white-space: nowrap;
  margin: 0;
  padding-right: 4px;
}

.tables__money-text_type_green {
  color: #40cf71;
}

.tables__currency-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  object-position: center;
}

.tables__not-found {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  margin: 0;
  padding: 50px 0;
}

@media (max-width: 550px) {
  .tables__id-text:hover {
    color: #5469dd;
  }
}
.dice {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 32px 64px;
  margin: 0 auto;
}

.dice__table-block {
  width: 100%;
  margin-top: 64px;
}

@media (max-width: 1150px) {
  .dice {
    padding: 0 0 64px;
  }


}

@media (max-width: 900px) {
  .dice {
    padding: 0 0 64px;
  }

}

@media (max-width: 730px) {
  .dice {
    padding: 28px 16px 64px;
  }

  
}


@media (max-width: 550px) {
  .dice {
    padding: 16px 16px 64px;
  }

  
}

.flip {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 32px 64px;
  margin: 0 auto;
}

.flip__table-block {
  width: 100%;
  margin-top: 64px;
}

@media (max-width: 1150px) {
  .flip {
    padding: 0 0 64px;
  }


}

@media (max-width: 900px) {
  .flip {
    padding: 0 0 64px;
  }

}

@media (max-width: 730px) {
  .flip {
    padding: 28px 16px 64px;
  }

  
}


@media (max-width: 550px) {
  .flip {
    padding: 16px 16px 64px;
  }

  
}

:root {
  --toastify-color-dark: #181C32;
  --toastify-font-family: "Rubik";
  --toastify-toast-width: 234px;
  --toastify-toast-min-height: unset;
  --toastify-color-progress-dark: transparent;
}

.app {
  width: 100%;
  /* max-width: 1500px; */
  min-height: 100vh;
  display: grid;
  grid-template-columns: 260px 1fr;
  margin: 0 auto;
}

.app__content {
  width: 100%;
  overflow: hidden;
}

.app__confirm-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  padding: 0 16px;
  z-index: 10000;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.app__confirm-popup_opened {
  visibility: visible;
  opacity: 1;
}

.app__popup-overlay {
  width: 0;
  height: 100vh;
  background: #000000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.app__confirm-popup_opened .app__popup-overlay {
  width: 100vw;
  opacity: 0.7;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.app__popup-container {
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #171821;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 24px;
  overflow: hidden;
  visibility: hidden;
  z-index: 10000;
  transition: visibility 0.3s ease-in-out;
}

.app__confirm-popup_opened .app__popup-container {
  visibility: visible;
}

.app__popup-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #fffafa;
  text-align: center;
  margin: 0;
}

.app__confirm-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-decoration: none;
  color: #ffffff;
  background: #8F00FF;
  border-radius: 12px;
  border: none;
  box-sizing: border-box;
  padding: 12px;
  margin-top: 24px;
  cursor: pointer;
}

.Toastify__toast-container {
  max-width: 288px;
  padding: 0;
}

.Toastify__toast-container--top-right {
  top: 75px;
  right: 34px;
}

.Toastify__toast {
  height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  box-shadow: 0px 4px 8px rgba(9, 7, 13, 0.25);
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 5px;
}

.Toastify__toast-body {
  align-items: flex-start;
  padding: 0;
}

.toast_type_copy .Toastify__toast-body {
  align-items: center;
}

.Toastify__toast-icon {
  padding: 2px;
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}

.Toastify__progress-bar {
  height: 0;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.6s ease-out both;
  animation: slide-in-right 0.6s ease-out both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fade-out {
  -webkit-animation: fade-out 2s ease-out both;
  animation: fade-out 2s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 1150px) {
  .app {
    grid-template-columns: 1fr;
  }

  .app__content {
    box-sizing: border-box;
    padding-top: 64px;
  }

  .app__overlay {
    width: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #13151b;
    opacity: 0;
    z-index: 109;
    transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
  }

  .app__overlay_opened {
    width: 100vw;
    opacity: 0.5;
    transition: width 0s ease 0s, opacity 0.4s ease-in-out;
  }
}

@media only screen and (max-width: 730px) {
  .app_toast-container_type_game.Toastify__toast-container--top-right {
    top: 274px;
    right: 16px;
  }

  .Toastify__toast-container--top-right {
    right: 16px;
  }
}

@media only screen and (max-width: 550px) {
  .Toastify__toast-container {
    left: unset;
  }

  .app_toast-container_type_game.Toastify__toast-container--top-right {
    top: 214px;
  }

  .Toastify__toast {
    border-radius: 8px;
    padding: 6px;
  }

  .Toastify__toast-icon {
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    padding: 1px;
  }
}

.limbo {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 32px 64px;
  margin: 0 auto;
}

.limbo__table-block {
  width: 100%;
  margin-top: 64px;
}

@media (max-width: 1150px) {
  .limbo {
    padding: 0 0 64px;
  }


}

@media (max-width: 900px) {
  .limbo {
    padding: 28px 16px 64px;
  }

  
}

@media (max-width: 730px) {
  .limbo {
    padding: 28px 16px 64px;
  }

  
}


@media (max-width: 550px) {
  .limbo {
    padding: 16px 16px 64px;
  }

  
}

.wallet-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.wallet-modal_opened {
  visibility: visible;
  opacity: 1;
}

.wallet-modal__overlay {
  width: 0;
  height: 100vh;
  background: #13151b;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.wallet-modal_opened .wallet-modal__overlay {
  width: 100vw;
  opacity: 0.5;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.wallet-modal__container {
  width: 100%;
  max-width: 488px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #13151b;
  border-radius: 32px;
  z-index: 10000;
  overflow: hidden;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.wallet-modal_opened .wallet-modal__container {
  visibility: visible;
}

.wallet-modal__close-btn {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  right: 24px;
  background: #1c1d28;
  border-radius: 24px;
  border: none;
  padding: none;
  cursor: pointer;
}

.wallet-modal__connect-block {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  gap: 32px;
  box-sizing: border-box;
  padding: 32px;
}

.wallet-modal__select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wallet-modal__select-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  margin: 0;
}

.wallet-modal__options-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  grid-auto-rows: 120px;
  grid-gap: 8px;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 16px 0 0;
}

.wallet-modal__option-item {
  width: 100%;
  height: 100%;
}

.wallet-modal__option-btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
  border-radius: 24px;
  border: none;
  padding: 22px 8px 8px;
  transition: background-color 0.3s ease-in;
}

.wallet-modal__option-btn_type_wallet {
  padding: 14px 8px 8px;
  cursor: pointer;
}

.wallet-modal__option-btn_selected {
  background: #1c1d28;
  pointer-events: none;
}

.wallet-modal__option-btn_disabled {
  pointer-events: none;
}

.wallet-modal__selected-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e874eb;
  border: 4px solid #1c1d28;
  border-radius: 200px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.wallet-modal__soon-box {
  position: absolute;
  top: 12px;
  right: 24px;
  background: #202228;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 4px 8px;
  z-index: 10;
}

.wallet-modal__option-btn_type_wallet .wallet-modal__soon-box {
  top: 4px;
}

.wallet-modal__soon-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.wallet-modal__option-icon {
  width: 54px;
  height: 54px;
  object-fit: contain;
  object-position: center;
}

.wallet-modal__option-btn_disabled .wallet-modal__option-icon {
  opacity: 0.5;
}

.wallet-modal__option-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding-top: 8px;
}

.wallet-modal__option-btn_disabled .wallet-modal__option-title {
  opacity: 0.5;
}

.wallet-modal__info-block {
  width: 100%;
  background: #1c1d28;
  box-sizing: border-box;
  padding: 32px;
}

.wallet-modal__info-title {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  margin: 0;
}

.wallet-modal__learn-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 16px;
  background: linear-gradient(#13151b, #13151b),
    linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-sizing: border-box;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
}

.wallet-modal__learn-btn-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  background: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
}

.walletconnect-modal__mobile__toggle {
  color: #13151b;
}

@media (max-width: 700px) {
  .wallet-modal__container {
    max-width: 288px;
  }

  .wallet-modal__close-btn {
    width: 34px;
    height: 34px;
    top: 28px;
    right: 16px;
  }

  .wallet-modal__close-btn .close-icon {
    width: 11px;
    height: 11px;
  }

  .wallet-modal__connect-block {
    grid-gap: 22px;
    gap: 22px;
    padding: 26px 16px;
  }

  .wallet-modal__select-title {
    font-size: 24px;
    line-height: 167%;
  }

  .wallet-modal__options-list {
    grid-template-columns: repeat(auto-fit, 74px);
    grid-auto-rows: 90px;
  }

  .wallet-modal__option-btn {
    border-radius: 12px;
    padding: 12px 8px 10px;
  }

  .wallet-modal__option-btn_type_wallet {
    padding: 12px 8px 4px;
  }

  .wallet-modal__selected-icon {
    width: 16px;
    height: 16px;
    border: 3px solid #1c1d28;
    top: 8px;
    right: 5px;
  }

  .wallet-modal__soon-box {
    top: 2px;
    right: 14px;
  }

  .wallet-modal__option-btn_type_wallet .wallet-modal__soon-box {
    top: 0;
  }

  .wallet-modal__soon-text {
    font-size: 10px;
    line-height: 160%;
  }

  .wallet-modal__option-icon {
    width: 44px;
    height: 44px;
  }

  .wallet-modal__option-title {
    font-size: 11px;
  }

  .wallet-modal__info-block {
    padding: 14px 40px;
  }

  .wallet-modal__info-title {
    font-size: 16px;
  }

  .wallet-modal__learn-btn {
    margin-top: 8px;
  }

  .wallet-modal__learn-btn-text {
    font-size: 14px;
    line-height: 171%;
  }
}

