.limbo {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 28px 32px 64px;
  margin: 0 auto;
}

.limbo__table-block {
  width: 100%;
  margin-top: 64px;
}

@media (max-width: 1150px) {
  .limbo {
    padding: 0 0 64px;
  }


}

@media (max-width: 900px) {
  .limbo {
    padding: 28px 16px 64px;
  }

  
}

@media (max-width: 730px) {
  .limbo {
    padding: 28px 16px 64px;
  }

  
}


@media (max-width: 550px) {
  .limbo {
    padding: 16px 16px 64px;
  }

  
}
