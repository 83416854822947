.wallet-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.wallet-modal_opened {
  visibility: visible;
  opacity: 1;
}

.wallet-modal__overlay {
  width: 0;
  height: 100vh;
  background: #13151b;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: width 0s ease 0.4s, opacity 0.4s ease-in-out;
}

.wallet-modal_opened .wallet-modal__overlay {
  width: 100vw;
  opacity: 0.5;
  transition: width 0s ease 0s, opacity 0.4s ease-in-out;
}

.wallet-modal__container {
  width: 100%;
  max-width: 488px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #13151b;
  border-radius: 32px;
  z-index: 10000;
  overflow: hidden;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out;
}

.wallet-modal_opened .wallet-modal__container {
  visibility: visible;
}

.wallet-modal__close-btn {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 24px;
  right: 24px;
  background: #1c1d28;
  border-radius: 24px;
  border: none;
  padding: none;
  cursor: pointer;
}

.wallet-modal__connect-block {
  width: 100%;
  display: grid;
  gap: 32px;
  box-sizing: border-box;
  padding: 32px;
}

.wallet-modal__select-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wallet-modal__select-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  margin: 0;
}

.wallet-modal__options-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  grid-auto-rows: 120px;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin: 16px 0 0;
}

.wallet-modal__option-item {
  width: 100%;
  height: 100%;
}

.wallet-modal__option-btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
  border-radius: 24px;
  border: none;
  padding: 22px 8px 8px;
  transition: background-color 0.3s ease-in;
}

.wallet-modal__option-btn_type_wallet {
  padding: 14px 8px 8px;
  cursor: pointer;
}

.wallet-modal__option-btn_selected {
  background: #1c1d28;
  pointer-events: none;
}

.wallet-modal__option-btn_disabled {
  pointer-events: none;
}

.wallet-modal__selected-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e874eb;
  border: 4px solid #1c1d28;
  border-radius: 200px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.wallet-modal__soon-box {
  position: absolute;
  top: 12px;
  right: 24px;
  background: #202228;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 4px 8px;
  z-index: 10;
}

.wallet-modal__option-btn_type_wallet .wallet-modal__soon-box {
  top: 4px;
}

.wallet-modal__soon-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.wallet-modal__option-icon {
  width: 54px;
  height: 54px;
  object-fit: contain;
  object-position: center;
}

.wallet-modal__option-btn_disabled .wallet-modal__option-icon {
  opacity: 0.5;
}

.wallet-modal__option-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 129%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding-top: 8px;
}

.wallet-modal__option-btn_disabled .wallet-modal__option-title {
  opacity: 0.5;
}

.wallet-modal__info-block {
  width: 100%;
  background: #1c1d28;
  box-sizing: border-box;
  padding: 32px;
}

.wallet-modal__info-title {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  margin: 0;
}

.wallet-modal__learn-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 16px;
  background: linear-gradient(#13151b, #13151b),
    linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-sizing: border-box;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
}

.wallet-modal__learn-btn-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  background: linear-gradient(92.29deg, #9747ff -42.66%, #d76bf0 118.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
}

.walletconnect-modal__mobile__toggle {
  color: #13151b;
}

@media (max-width: 700px) {
  .wallet-modal__container {
    max-width: 288px;
  }

  .wallet-modal__close-btn {
    width: 34px;
    height: 34px;
    top: 28px;
    right: 16px;
  }

  .wallet-modal__close-btn .close-icon {
    width: 11px;
    height: 11px;
  }

  .wallet-modal__connect-block {
    gap: 22px;
    padding: 26px 16px;
  }

  .wallet-modal__select-title {
    font-size: 24px;
    line-height: 167%;
  }

  .wallet-modal__options-list {
    grid-template-columns: repeat(auto-fit, 74px);
    grid-auto-rows: 90px;
  }

  .wallet-modal__option-btn {
    border-radius: 12px;
    padding: 12px 8px 10px;
  }

  .wallet-modal__option-btn_type_wallet {
    padding: 12px 8px 4px;
  }

  .wallet-modal__selected-icon {
    width: 16px;
    height: 16px;
    border: 3px solid #1c1d28;
    top: 8px;
    right: 5px;
  }

  .wallet-modal__soon-box {
    top: 2px;
    right: 14px;
  }

  .wallet-modal__option-btn_type_wallet .wallet-modal__soon-box {
    top: 0;
  }

  .wallet-modal__soon-text {
    font-size: 10px;
    line-height: 160%;
  }

  .wallet-modal__option-icon {
    width: 44px;
    height: 44px;
  }

  .wallet-modal__option-title {
    font-size: 11px;
  }

  .wallet-modal__info-block {
    padding: 14px 40px;
  }

  .wallet-modal__info-title {
    font-size: 16px;
  }

  .wallet-modal__learn-btn {
    margin-top: 8px;
  }

  .wallet-modal__learn-btn-text {
    font-size: 14px;
    line-height: 171%;
  }
}
