.side-bar {
  width: 100%;
  max-width: 320px;
  background-color: #181C32;
  z-index: 10000;
  border-radius: 0 0 20px 20px;

}

.side-bar__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  box-sizing: border-box;
  padding: 16px 32px;
}

.side-bar__main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.side-bar__logo-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-bar__logo {
  width: 90px;
  height: 32px;
  cursor: pointer;
}

.side-bar__close-button {
  display: none;
}

.side-bar__sound-block {
  display: none;
}

.side-bar__links-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

.side-bar__links-list_type_games {
  margin: 40px 0 0;
}

.side-bar__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  margin-top: 16px;
}

.side-bar__link_selected {
  opacity: 1;
}

.side-bar__link_disabled {
  opacity: 0.15;
  pointer-events: none;
}

.side-bar__link:hover {
  opacity: 0.8;
}

.side-bar__link-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-bar__link-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #ffffff;
  margin: 0;
  padding-left: 8px;
}

.side-bar__nav-block {
  display: flex;
  flex-direction: column;
  padding-top: 108px;
}

.side-bar__nav-block_type_network {
  padding-top: 40px;
}

.side-bar__nav-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;

  color: #FFF;
  opacity: 0.5;
  margin: 0;
}

.side-bar__info-block {
  display: none;
}

.side-bar__powered-by{

  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: auto 0 0;
}

@media (max-width: 1150px) {
  .side-bar {
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
  }

  .side-bar_opened {
    transform: translateX(0);
  }

  .side-bar__container {
    /* height: 100vh; */
    position: relative;
    bottom: 0;
    top: unset;
    padding: 0;
  }

  .side-bar__main-content {
    flex: auto;
    height: calc(100% - 57px);
    box-sizing: border-box;
    padding: 16px;
  }

  .side-bar__close-button {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
  }

  .side-bar__close-button:hover {
    background: rgba(255, 255, 255, 0.04);
  }

  .side-bar__close-button .close-icon-fill {
    fill-opacity: 0.5;
  }

  .side-bar__sound-block {
    display: grid;
    grid-template-columns: repeat(2, 32px);
    gap: 8px;
    margin-top: 24px;
  }

  .side-bar__sound-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1E223C;
    border-radius: 8px;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .side-bar__links-container {
    width: 100%;
    height: calc(100% - 38px - 32px - 24px);
    min-width: 100%;
    overflow: scroll;
  }

  .side-bar__links-list_type_games {
    margin: 0;
  }

  .side-bar__nav-block {
    padding-top: 44px;
  }

  .side-bar__nav-block_type_network {
    padding-top: 44px;
  }

  .side-bar__info-block {
    flex-shrink: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    border-top: 1px solid #393a40;
    box-sizing: border-box;
    padding: 12px 16px;
  }

  .side-bar__demo-block {
    display: flex;
    align-items: center;
    background: #1E223C;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 7px 16px;
  }

  .side-bar__currency-icon {
    width: 18px;
    height: 18px;
  }

  .side-bar__demo-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 129%;
    margin: 0;
    padding-left: 6px;
    white-space: nowrap;
  }

.side-bar__info-block_user{
  grid-template-columns: 1fr;
}

}

@media (max-width: 550px) {
  .side-bar__main-content {
    padding: 12px 16px;
  }

  .side-bar__close-button:hover {
    background: transparent;
  }

  .side-bar__sound-block {
    margin-top: 20px;
  }

  .side-bar__link {
    margin-top: 14px;
  }

  .side-bar__link:hover {
    opacity: 0.5;
  }

  .side-bar__link_selected:hover {
    opacity: 1;
  }

  .side-bar__nav-block {
    padding-top: 36px;
  }

  .side-bar__nav-block_type_network {
    padding-top: 36px;
  }
}
