@font-face {
    font-family: 'Caudex';
    src: url('Caudex-Italic.eot');
    src: url('Caudex-Italic.eot?#iefix') format('embedded-opentype'),
        url('Caudex-Italic.woff2') format('woff2'),
        url('Caudex-Italic.woff') format('woff'),
        url('Caudex-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Caudex';
    src: url('Caudex.eot');
    src: url('Caudex.eot?#iefix') format('embedded-opentype'),
        url('Caudex.woff2') format('woff2'),
        url('Caudex.woff') format('woff'),
        url('Caudex.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caudex';
    src: url('Caudex-Bold.eot');
    src: url('Caudex-Bold.eot?#iefix') format('embedded-opentype'),
        url('Caudex-Bold.woff2') format('woff2'),
        url('Caudex-Bold.woff') format('woff'),
        url('Caudex-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Caudex';
    src: url('Caudex-BoldItalic.eot');
    src: url('Caudex-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Caudex-BoldItalic.woff2') format('woff2'),
        url('Caudex-BoldItalic.woff') format('woff'),
        url('Caudex-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

