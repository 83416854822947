.banner {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 40px 32px 48px;
  margin: 0 auto;
}

.banner__title-box {
  width: 100%;
  max-width: 440px;
  display: flex;
  flex-direction: column;
}

.banner__title {
  color: #FFF;
  font-family: 'Caudex';
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 49.2px */
  letter-spacing: -0.82px;
  margin: 0;
  
}

.banner__title_type_gradient {

  font-family: 'Caudex';
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 49.2px */
  letter-spacing: -0.82px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #BD00FF 0%, #0AF0FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner__subtitle {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #FFF;
  margin: 0;
  padding-top: 16px;
}

.banner__subtitle_type_return {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  color: #0AF0FF;


  box-sizing: border-box;


}

.banner__image {
  width: 808px;
  height: 538px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: -74px;
  right: -175px;
  z-index: -1;
}

.banner__back-ellipse {
  width: 1005px;
  height: 1005px;

  position: absolute;
  top: -360px;
  right: -580px;
  z-index: -2;
}

@media (max-width: 1150px) {
  .banner {
    padding: 88px 32px 96px;
  }

  .banner__subtitle {
    padding-top: 20px;
  }

  .banner__image {
    top: -26px;
    right: -80px;
  }

  .banner__back-ellipse {
    top: -312px;
    right: -546px;
  }
}

@media (max-width: 1040px) {
  .banner__image {
    right: -175px;
  }
}

@media (max-width: 900px) {
  .banner {
    padding: 68px 16px 0;
  }

  .banner__title {
    font-size: 36px;
  }

  .banner__image {
    width: 688px;
    height: 482px;
    top: -46px;
    right: -150px;
  }
}

@media (max-width: 710px) {
  .banner__title {
    font-size: 32px;
  }

  .banner__image {
    width: 568px;
    height: 427px;
    right: -120px;
  }
}

@media (max-width: 620px) {
  .banner {
    padding: 268px 16px 0;
  }

  .banner__title {

  }

  .banner__subtitle {
    font-size: 14px;
    line-height: 200%;
    padding-top: 20px;
  }

  .banner__image {
    top: -101px;
    right: -50px;
  }
}

@media (max-width: 460px) {
  .banner__image {
    right: -120px;
  }
}