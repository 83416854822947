.games {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 32px;
  margin: 64px auto 0;
}

.games__title {
  color: #BD00FF;
  font-family: 'Caudex';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 111.111% */
  letter-spacing: -0.72px;
  margin: 0;
}

.games__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 250px;
  gap: 24px;
  list-style: none;
  padding: 0;
  margin: 24px 0 0;
}

.games__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
  bottom: 0;
  cursor: pointer;
  transition: bottom 0.3s ease-in;
}

.games__link:hover {
  bottom: 5px;
}

.games__link_disabled {
  pointer-events: none;
}

.games__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 20px 20px 30px 32px;
}

.games__return {
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 0.03em;
  /* text-transform: uppercase; */
  color: #FFF;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  padding: 4px 8px;
  margin: 0;
  position: relative;
  z-index: 1;
}

.games__text-block {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.games__card-title {
  color: #FFF;
  font-family: 'Caudex';
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 88.462%;
  letter-spacing: -1.04px;
  margin: 0;
  max-width: 200px;
}

.games__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: #FFF;
  margin: 0;
  padding-top: 8px;
}

.games__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.games__back-ellipse {
  width: 609px;
  height: 609px;
  position: absolute;
  left: -31px;
  top: -185px;
  z-index: -2;
}

.games__card-bg-fade {
  z-index: 0;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #151A2F 0%, rgba(21, 26, 47, 0.00) 100%);
}

.games__back-ellipse_type_coin-flip {
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #f3c2d7 0%,
      #eb74a6 100%);
}

.games__back-ellipse_type_mega-dice {
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #8d99d8 0%,
      #3f56ce 100%);
}

.games__back-ellipse_type_crash {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #F4AFFF 0%, #CF48FF 100%);
}


.games__back-ellipse_type_limbo {
  background: radial-gradient(71.24% 74.44% at 78.49% 51.97%, #FFCF33 0%, #FF6E00 100%);
}

.games__back-ellipse_type_lucky-sevens {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #95DFFF 0%, #1E93FF 100%);
}

/* .games__back-ellipse_type_dragon {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #AFFFDE 0%, #08D27D 100%);
} */

.games__back-ellipse_type_dragon {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #DEDEDE 0%, #757278 100%);
}

.games__back-ellipse_type_shuttle {
  background: radial-gradient(49.24% 51.45% at 78.49% 51.97%, #C395FF 0%, #811EFF 100%);
}


.games__back-ellipse_type_roulette {
  /*background: radial-gradient(
    45.48% 43.53% at 78.49% 51.97%,
    #f1c4ff 0%,
    #b67af3 100%
  );*/
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #dedede 0%,
      #757278 100%);
}

.games__back-ellipse_type_plinko {
  /*background: radial-gradient(
      45.48% 43.53% at 78.49% 51.97%,
      #ceeff7 0%,
      #1abcde 100%
    ),
    radial-gradient(45.48% 43.53% at 78.49% 51.97%, #8d99d8 0%, #3f56ce 100%);*/
  background: radial-gradient(45.48% 43.53% at 78.49% 51.97%,
      #dedede 0%,
      #757278 100%);
}

@media (max-width: 1150px) {
  .games__list {
    gap: 16px;
  }
}

@media (max-width: 900px) {
  .games {
    padding: 0 16px;
  }

  .games__title {
    font-size: 28px;

  }

  .games__list {
    grid-auto-rows: 200px;
    margin: 20px 0 0;
  }

  .games__card {
    padding: 28px 12px;
  }

  .games__card-title {
    font-size: 24px;

  }

  .games__card-text {
    font-size: 14px;

  }
}

@media (max-width: 710px) {
  .games {
    margin: 40px auto 0;
  }
}

@media (max-width: 700px) {

}

@media (max-width: 585px) {
  .games__card-title {
    font-size: 20px;
  }

  .games__card-text {
    font-size: 12px;
  }
}

@media (max-width: 550px) {
  .games__link:hover {
    bottom: 0;
  }
}

@media (max-width: 525px) {
  .games__list {
    grid-template-columns: 1fr;
  }

  .games__card-title {
    font-size: 24px;
  }

  .games__card-text {
    font-size: 14px;
  }

  .games__image {
    object-fit: contain;
    right: 0;
  }
}